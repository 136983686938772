import Image from "components/Image"
import richText from "utilities/richText"

function Card({ image, content }) {
  return (
    <div className="image-card lg:basis-1/2 xl:basis-1/3 py-8 lg:py-0 first:pt-0 last:pb-0 lg:px-12 flex flex-col items-center">
      <div className=" relative w-max h-max after:content-[''] after:w-full after:h-full after:bg-teal after:rounded-full after:absolute after:top-2 after:left-2 aspect-square z-0 mb-6">
        <Image
          src={image.filename}
          alt={image.alt}
          width={246}
          height={246}
          className="rounded-full relative z-10 aspect-square"
        />
      </div>
      <div className="prose-h3:text-m2 prose-h3:pb-4 prose-p:font-light prose-p:leading-7 text-center mb-8">
        {richText(content)}
      </div>
    </div>
  )
}

export default function ImageCardSection({ blok }) {
  return (
    <section className="px-8 py-12">
      <h2 className="text-center pb-12 uppercase">{blok.heading}</h2>
      <div className="image-card-section max-w-screen-xl mx-auto flex flex-wrap lg:gap-y-12 divide-y lg:divide-y-0 divide-blue justify-center">
        {blok.cards.map((card, idx) => (
          <Card {...card} key={idx} />
        ))}
      </div>
    </section>
  )
}
